import React from 'react'
import styled from 'styled-components';

import ProjectItem from '../components/ProjectItem';
import Spotifyico from '../media/spotify.png'
import Youtubeico from '../media/youtube.png'

/* Covers*/
import Vidalokatenorio from '../media/vidalokatenorio.jpg'
import EuteamoCover from '../media/euteamo.png'
import OnlyoneCover from '../media/onlyone.png'
import aelesejaahonra from '../media/aeleagloria.png'
import snarkypuppy from '../media/snarkypuppy.png'
import calmaria from '../media/calmaria.png'
import Header from '../components/Header';
import Footer from '../components/Footer';


const Wrapper = styled.main`
    display: grid;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-items: center;
  grid-template-rows: 1fr 7fr;
  background-color: #0E0D1E;

  h2{
  color: #f2f2f2;
  font-weight: 200;
  font-size: 2.0em;
  padding: 10px;
  font-family: 'Mulish', sans-serif;
  width: 98%;
  background-color: #0b427a24;
  display: flex;
  justify-content: center;
      align-items: center;


}
section{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  align-self: baseline;
}
@media only screen and (max-width: 1440px) {
  section{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  .overlay{
    font-size: 1cqw;
  }
  .modal-box{
    grid-template-columns: 1fr 2fr 2fr 1fr;
  }
}
@media only screen and (max-width: 1210px) {
  section{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  #portifoliopage {
    grid-template-rows: 1fr 7fr;
}
  .overlay{
    font-size: 2cqw;
  }
  .modal-box{
    grid-template-columns: 1fr 2fr 2fr 1fr;
  }
  .modal-content {
    width: 87%;
}
@media only screen and (max-width: 1024px) {
  section{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .overlay {
    font-size: 2cqw;
}
.modal-box{
  grid-template-columns: 1fr 2fr 2fr 1fr;
  display: flex;
    flex-direction: column;
    row-gap: 30px;
}
.modal-content {
  width: 80%;
}

}
@media only screen and (max-width: 768px) {
  section{
    grid-template-columns: 1fr 1fr;
  }

  #portifoliopage {
    grid-template-rows: 1fr 7fr;
}
  .overlay {
    font-size: 2cqw;
}
}
@media only screen and (max-width: 425px) {
  #portifoliopage {
    grid-template-rows: 1fr 7fr;
}
  .overlay {
    font-size: 2.4cqw;
}
.modal-content {
  width: 80%;
}
}}
`;
const Body = styled.body`
  display: grid;
  grid-template-rows: 1fr 13fr 1fr;
  justify-content: center;
  background-color: #0E0D1E;
  align-items: center;
  font-family: 'Mulish', sans-serif;
  justify-items:center;
  height: 100vh;
`


const ProjectPage = () => {
  return (
    <Body>
        <Header/>
    <Wrapper>
     <h2>Portifolio</h2>
    <section>

        {/* Eu Te Amo - Gabriela Costa */}
        <ProjectItem
        img={EuteamoCover}
        songyear={'2024'}
        songname={'Eu Te Amo'}
        artistname={'Gabriela Costa'}
        jobtype={'Recording, Mixing & Mastering Engineer'}

        credits={[
            "Composer/Singer/Guitar: Gabriela Costa",
            "Keys: Breno Barbosa",
            "Trumpet: Haniel Tenório",
            "Recording, Mixing, Mastering Engineer: Victor Alves",
            "Video: @fillps",
        ].map((credit, index) => (
            <React.Fragment key={index}>
                {credit}
                <br />
            </React.Fragment>
        ))}

       
        />
        
        {/* I'm Not The Only One - Petrio */}

        <ProjectItem
        img={OnlyoneCover}
        songyear={'2024'}
        songname={" I'm not the only one"}
        artistname={'Pretrio at Venicce'}
        jobtype={'Recording, Mixing & Mastering Engineer'}

        credits={[
            "Composer: Gnarls Barkley",
            "Keys/Vocal: Elias Augusto",
            "Bass: Rhuan Costa",
            "Drums: Rafa Black",
            "Recording, Mixing, Mastering Engineer: Victor Alves",
            "Video: @fillps",
        ].map((credit, index) => (
            <React.Fragment key={index}>
                {credit}
                <br />
            </React.Fragment>
        ))}

       
        />

<ProjectItem
        img={Vidalokatenorio}
        songyear={'2023'}
        songname={" Vida Loka Parte 2"}
        artistname={'Tenorio Trio'}
        jobtype={'Recording, Mixing & Mastering Engineer'}

        credits={[
            "Composer: Racionais MC's",
            "Trumpet: Hanniel Tenório",
            "Keys/Synth/Bass: Elias Augusto",
            "Drums: Rafa Black",
            "Recording, Mixing, Mastering Engineer: Victor Alves",
        ].map((credit, index) => (
            <React.Fragment key={index}>
                {credit}
                <br />
            </React.Fragment>
        ))}

        urlvideo={'https://www.youtube.com/watch?v=z9A0WfTVzAg'}
        videoico={Youtubeico}
        urlspotify={'https://www.youtube.com/watch?v=z9A0WfTVzAg'}
        spotifyico={Spotifyico}
        />

         {/* A Ele seja a Honra - Coração de Servo*/}

         <ProjectItem
        img={aelesejaahonra}
        songyear={'2023'}
        songname={'A Ele Seja a Honra - Ao Vivo'}
        artistname={'Coração de Servo'}
        jobtype={'Mixing & Mastering Engineer'}

        credits={[
            'Vocals: Weder and Renata Cruz',
            'Guitar: Gabriel Capuccho',
            'Bass: Rebeca Oliveira',
            'Drums: Juan Thomas',
            'Keys and Synth: Laercio Cavalcanti',
            
            'Composer: Weder Cruz e Gabriel Capuccho',
            'Music Producer: Danilo Snow',
            'Mixing & Mastering Engineer: Victor Alves',
            'Video: @fillps',
        ].map((credit, index) => (
            <React.Fragment key={index}>
                {credit}
                <br />
            </React.Fragment>
        ))}

        urlvideo={'https://www.youtube.com/watch?v=xhN8TyyTzCo'}
        videoico={Youtubeico}
        urlspotify={'https://www.youtube.com/watch?v=BhPyF0BQpF0&list=PL85ITvJ7FLohz54DLfinJeHi7DrHGT2_U&index=3'}
        spotifyico={Spotifyico}
        />
          {/* A Ele seja a Honra - Coração de Servo*/}

          <ProjectItem
        img={snarkypuppy}
        songyear={'2022'}
        songname={'Snarky Puppy - Medley'}
        artistname={'Elias Augusto'}
        jobtype={'Recording, Mixing & Mastering Engineer'}

        credits={[
            'Keys and Synth: Elias Augusto',
            'Guitar: Junior Souza',
            'Bass: Marcus Ben',
            'Drums: Willan Baru',
            'Mixing & Mastering Engineer: Victor Alves',
            'Video editting: Willan Baru',
        ].map((credit, index) => (
            <React.Fragment key={index}>
                {credit}
                <br />
            </React.Fragment>
        ))}

        urlvideo={'https://www.youtube.com/watch?v=f3Usr_jK4D4'}
        videoico={Youtubeico}
        urlspotify={'https://www.youtube.com/watch?v=BhPyF0BQpF0&list=PL85ITvJ7FLohz54DLfinJeHi7DrHGT2_U&index=3'}
        spotifyico={Spotifyico}
        />
          {/* Calmaria - FWF*/}

          <ProjectItem
        img={calmaria}
        songyear={'2021'}
        songname={'Calmaria'}
        artistname={'FWF'}
        jobtype={'Music Producer, Recording, Mixing & Mastering Engineer'}

        credits={[
            'Composer: Taynara Araujo',
            'Melody: Taynara Araujo, Rafael Faleiro',
            'Keys, Drums, Loops & Synths: Victor Alves',
            'Bass & Guitar: Matheus Santos',
            'Musical arrangement: Victor Alves e Matheus Santos',
            'Music Producer: Victor Alves',
            'Mixing & Mastering Engineer: Victor Alves',
            'Video, Editing e Photography: Felipe Soares - @fillps',
            'Artboard/Cover: Guilherme Veras',
            'Singers: Thaila Giordani, Filipe Saboia',
        ].map((credit, index) => (
            <React.Fragment key={index}>
                {credit}
                <br />
            </React.Fragment>
        ))}
        
        urlvideo={'https://www.instagram.com/tv/CSE6cOtHTrR/?igsh=MW5zeDN0ZWltZWY3Yw=='}
        videoico={Youtubeico}
        urlspotify={'https://open.spotify.com/track/3yWZkhaDLGsKLB9JZAyu3p?si=MjEovLMZQXmZZFNWwstaJg&context=spotify%3Aalbum%3A4QXusxqllyOZLlZkVhVyYD'}
        spotifyico={Spotifyico}
        />
    </section>
    <Footer/>

        </Wrapper>
    </Body>
  )
}

export default ProjectPage;