import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import cover from '../media/cover.jpg'

const Wrapper = styled.main`
  width: 100%;
  height: 100vh;
  display: grid;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  color: #f1f1f1;
  font-family: verdana;
  font-weight: 300;
  background-image: url(${cover});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

.abouttext{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 80%;

}
h2{
  grid-column: 2;
  grid-row: 1;
}

h3{
  grid-column: 2;
  grid-row: 2;
}

p{
  grid-column: 2;
  grid-row: 3;
}
.projectbtn{
  color: #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  width:20%;
  background-color: #0e0d1ea1;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 20px;
}
a{
  text-decoration: none;

}

@media only screen and (max-width: 1096px) {
    .projectbtn{
    width:20%;
    }
}
@media only screen and (max-width: 768px) {
    .projectbtn{
    width:25%;
    }
}
@media only screen and (max-width: 768px) {
    .projectbtn{
    width:23%;
    }
}
@media only screen and (max-width: 633px) {
    .projectbtn{
    width:40%;
    }
}@media only screen and (max-width: 377px) {
    .projectbtn{
    width:50%;
    }
}
`;

const aboutme = () => {
  return (
    <Wrapper>
        <div className='abouttext'>
        <h2>Hi! I'm Victor</h2>
            <h3> Sound Engineer and Musical Producer.</h3>
            <p>
                 A 24-year-old deeply passionate about crafting emotive sonic experiences.
                From beats to mixes, I merge technical precision with creative intuition, approaching each project as a unique opportunity to tell a
                compelling story through sound.

                Let's collaborate and create something exceptional together!
            </p>
            <Link to='/portifolio'><div className="projectbtn">My Portifolio</div></Link>
         </div>

  </Wrapper>
  )
}

export default aboutme