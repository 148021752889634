import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ProjectPage from './pages/ProjectsPage';
import AboutmePage from './components/aboutme';
import NotFoundPage from './pages/NotFoundPage';
import ContactPage from './pages/ContactPage';


const router = createBrowserRouter([
  {
  path: '/',
  element: <AboutmePage/>,
  errorElement: <NotFoundPage/>,
},
{
  path: '/portifolio',
  element: <ProjectPage/>
},
{
  path: '/contact',
  element: <ContactPage/>
},
]);

function App() {
  return (
    <div>
    <RouterProvider router={router}/>
    </div>
  );
}

export default App;
