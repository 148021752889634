import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.footer`
  width: 100%;
  height: 5vh;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  color:#eaeaea;
  font-family: verdana;
`;

const Footer = () => {
  return (
   <Wrapper>&copy; Victor Alves - All Rigths Reserved</Wrapper>
  )
}

export default Footer