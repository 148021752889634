import React from 'react'
import styled from 'styled-components';
import logo from '../media/logowhite.png';
import { Link } from 'react-router-dom';

const Wrapper = styled.nav`
  width: 100%;
  height: 100%;
  background-color: #0E0D1E;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  font-family: 'Mulish', sans-serif;
  column-gap: 1em;
  font-size: 16px;

  img{
  grid-column: 1;
  grid-row: 1;
  width: 170px;
padding: 20px;
}

ul{
  display: flex;
  flex-direction: row;
  list-style: none;
  column-gap: 1.5em;
  grid-column: 3;
  grid-row: 1;
  height: 100%;
  color: #fff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

}
a{
  color: #eaeaea;
  text-decoration: none;
}



`;


const Header = () => {
  return (
    <Wrapper>
      <Link to='/'><img src={logo} alt='logo'/></Link>
        <ul>
          <Link to='/'><li>About Me</li></Link>
          <Link to='/portifolio'><li>Portifolio</li></Link>
          <Link to='/contact'> <li>Contact Me</li></Link>
        </ul>
    </Wrapper>
  )
}

export default Header;