import { Modal } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import styled from 'styled-components';


const Wrapper = styled.div`
     width: 100%;
  height: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
  position:relative;

   img{
  width: 100%;
}

`;

const Overlay = styled.div`  font-family: verdana;
font-size: 1.2em;
opacity: .8;
width: 100%;
height: 100%;
color: #f2f2f2;
background-color: #000;
position: absolute;
display:flex;
row-gap:7px;
align-self: center;
justify-items: center;
flex-direction: column;
justify-content: center;
align-items: flex-start;
top:0;

span{
  padding-left: 30px;
}
.songname{
  text-transform: uppercase;
  font-weight: 600;
}

.jobtype{
  width: 80%
}
.listen{
  margin-top: 10px;
}

.modal{
  display: none ; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
@media only screen and (max-width: 1600px) {
  font-size: .8em;
 
  section{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
 
  .modal-box{
    grid-template-columns: 1fr 2fr 2fr 1fr;
  }
}
@media only screen and (max-width: 1210px) {
  section{
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
  #portifoliopage {
    grid-template-rows: 1fr 7fr;
}
  .overlay{
    font-size:1em;
  }
  .modal-box{
    grid-template-columns: 1fr 2fr 2fr 1fr;
  }
  .modal-content {
    width: 87%;
}
@media only screen and (max-width: 1024px) {
  font-size: .8em;

  section{
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

.modal-box{
  grid-template-columns: 1fr 2fr 2fr 1fr;
  display: flex;
    flex-direction: column;
    row-gap: 30px;
}
.modal-content {
  width: 80%;
}

}
@media only screen and (max-width: 768px) {
    font-size: 1.1em;
}

@media only screen and (max-width: 510px) {

    font-size: .5em;


}
}
`;

const  ModalBox = styled.div`
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
background-color: #0c1d2b;
padding: 1em;
width: 66%;
height: 40%;
border-style: none;
border: 0;
display: grid;
grid-template-columns: 3fr 4fr;
align-items:center;
color:#eaeaea;
justify-items: center;

img{
   width:100%;
  }

  .songinfos{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 1em;
}
.credits{
  padding-left: 1em;

}
.songname{
  text-transform: uppercase;
  font-weight: 600;
}
.songyear{
  font-size: 0.8em;
}

 a{
  color: #eaeaea;
 }

 .listen{
  display: flex;
  flex-direction: column;
  padding-top: 1em;
 }

 .listen a{
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  padding-top: 0.5em;
 }
 @media only screen and (max-width: 1024px) {
  grid-template-rows: 1fr 3fr;
  grid-template-columns: 1fr;
  width: 70%;
  height: 90%;
  min-height: fit-content;
  display: grid;

  .credits{
    width: 100%;
  }
  .text{
    font-size: 1.5em;
  }
}



@media only screen and (max-width:768px){
  grid-template-rows: 1fr 3fr;
  grid-template-columns: 1fr;
  width: 70%;
  height: 90%;
  min-height: fit-content;
  display: grid;

  .credits{
    width: 100%;
  }
  .text{
    font-size: 1.2em;
  }
@media only screen and (max-width:430px){
  grid-template-rows: 1fr 3fr;
  grid-template-columns: 1fr;
  width: 70%;
  height: 70%;
  min-height: fit-content;
  display: grid;

  .credits{
    width: 100%;
  }
  .text{
    font-size: 1em;
  }
  
  .songinfos {
    display: flex;
    flex-direction: column;
    padding-left: 20px;

}
  
}}
`;

const ProjectItem = (props) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Wrapper >
      <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
    <img src={props.img} alt={props.alt}/>

    {isHovering && (
          <Overlay>
            
            <span className="songyear">{props.songyear}</span>
            <span className="songname">{props.songname}</span>
            <span className="artistname">{props.artistname}</span>
            <span className="jobtype">{props.jobtype}</span>
           
            <span onClick={handleOpen}>See More</span>
              <Modal className='modal'
                open={open}
                onClose={handleClose}
              >
                <ModalBox>
                <img src={props.img} alt={props.alt} style={{width:'100%'}}/>
                <div className='text'>
                  <div className='songinfos'>
                    <span className="songyear">{props.songyear}</span>
                    <span className="songname">{props.songname}</span>
                    <span className="artistname">{props.artistname}</span>
                  </div>
                  <p class="credits">
                      {props.credits}
                  </p>
                  <span className="listen">
                       <a href={props.urlvideo} target="_blank" rel="noopener noreferrer"><img src={props.videoico} alt='youtube icon' style={{width:'25px', paddingRight: '10px'}}/ > Watch Now</a>
                       <a href={props.urlspotify} target="_blank" rel="noopener noreferrer"><img src={props.spotifyico} alt='spotify icon' style={{width:'25px', paddingRight: '10px'}}/>Listen Now</a>
                </span>
                </div>
                </ModalBox>
              </Modal>
                
          </Overlay>
        )}
    </div>

    </Wrapper>
  )
}

export default ProjectItem;